<template>
  <v-container fill-height>
    <v-row no-gutters>
      <v-col cols="12"
        ><div class="white--text text-center text-h1 h1-text">
          Deslogueado
        </div></v-col
      >
    </v-row>
  </v-container>
</template>
<script>
import { firebase } from "@/firebase";
export default {
  mounted() {
    this.$store.commit("setUser", null);
    firebase.auth().signOut();
    setTimeout(() => this.$router.push({ name: "login" }), 3000);
  },
};
</script>
<style scoped>
.h1-text {
  font-size: 2rem !important;
  letter-spacing: 4px !important;
  font-weight: 400 !important;
}
</style>
